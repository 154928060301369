import { useMemo } from 'react';
import useWindowResize from 'hooks/useWindowResize';
import breakpoints from 'constants/breakpoints';

export default function useScreenSizeDetect() {
  const { width } = useWindowResize();

  return useMemo(() => {
    return {
      isMobile: width < breakpoints.mobile,
      isTablet: width >= breakpoints.mobile && width < breakpoints.tablet,
      isDesktop: width >= breakpoints.tablet,
    };
  }, [width]);
}
