import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import type { TooltipProps } from '@mui/material/Tooltip';
import type { CustomTypographyProps } from 'components/Typography/types.d';
import Typography from 'components/Typography';
import Tooltip from 'components/Tooltip';
import { ReactComponent as InfoIcon } from 'public/assets/icons/info.svg';
import styles from './HeadlineWithInfo.module.scss';
export interface HeadlineWithInfoProps extends HTMLAttributes<HTMLDivElement> {
  tooltipContent?: React.ReactNode;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
  variant?: CustomTypographyProps['variant'];
  iconSize?: 'large' | 'medium';
  classes?: {
    typography?: string;
  };
}
const HeadlineWithInfo: React.FC<HeadlineWithInfoProps> = ({
  children,
  tooltipContent,
  tooltipProps = {},
  variant = 'h9',
  iconSize = 'large',
  className,
  classes = {},
  ...props
}) => {
  return <div className={cx(styles.root, className)} {...props} data-sentry-component="HeadlineWithInfo" data-sentry-source-file="HeadlineWithInfo.tsx">
      <Typography variant={variant} color="secondary" component="h6" className={cx(styles.title, classes?.typography)} data-sentry-element="Typography" data-sentry-source-file="HeadlineWithInfo.tsx">
        {children}
      </Typography>
      {tooltipContent && <Tooltip title={tooltipContent} enterTouchDelay={0} leaveTouchDelay={7000} arrow {...tooltipProps} className={cx(styles.tooltip, {
      [styles.tooltipMedium]: iconSize === 'medium'
    })}>
          <div>
            <InfoIcon className={cx(styles.icon, {
          [styles.iconMedium]: iconSize === 'medium'
        })} />
          </div>
        </Tooltip>}
    </div>;
};
export default HeadlineWithInfo;