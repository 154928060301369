export { ReactComponent as adult } from './adult.svg';
export { ReactComponent as apps } from './apps.svg';
export { ReactComponent as art } from './art.svg';
export { ReactComponent as babies } from './babies.svg';
export { ReactComponent as beauty } from './beauty.svg';
export { ReactComponent as blogs } from './blogs.svg';
export { ReactComponent as books } from './books.svg';
export { ReactComponent as business } from './business.svg';
export { ReactComponent as career } from './career.svg';
export { ReactComponent as construction } from './construction.svg';
export { ReactComponent as courses } from './courses.svg';
export { ReactComponent as crypto } from './crypto.svg';
export { ReactComponent as darknet } from './darknet.svg';
export { ReactComponent as design } from './design.svg';
export { ReactComponent as economics } from './economics.svg';
export { ReactComponent as education } from './education.svg';
export { ReactComponent as edutainment } from './edutainment.svg';
export { ReactComponent as entertainment } from './entertainment.svg';
export { ReactComponent as erotica } from './erotica.svg';
export { ReactComponent as esoterics } from './esoterics.svg';
export { ReactComponent as food } from './food.svg';
export { ReactComponent as gambling } from './gambling.svg';
export { ReactComponent as games } from './games.svg';
export { ReactComponent as handmade } from './handmade.svg';
export { ReactComponent as health } from './health.svg';
export { ReactComponent as instagram } from './instagram.svg';
export { ReactComponent as language } from './language.svg';
export { ReactComponent as law } from './law.svg';
export { ReactComponent as marketing } from './marketing.svg';
export { ReactComponent as medicine } from './medicine.svg';
export { ReactComponent as music } from './music.svg';
export { ReactComponent as nature } from './nature.svg';
export { ReactComponent as news } from './news.svg';
export { ReactComponent as other } from './other.svg';
export { ReactComponent as pics } from './pics.svg';
export { ReactComponent as politics } from './politics.svg';
export { ReactComponent as psychology } from './psychology.svg';
export { ReactComponent as quotes } from './quotes.svg';
export { ReactComponent as religion } from './religion.svg';
export { ReactComponent as sales } from './sales.svg';
export { ReactComponent as shock } from './shock.svg';
export { ReactComponent as sport } from './sport.svg';
export { ReactComponent as tech } from './tech.svg';
export { ReactComponent as telegram } from './telegram.svg';
export { ReactComponent as transport } from './transport.svg';
export { ReactComponent as travels } from './travels.svg';
export { ReactComponent as video } from './video.svg';
