'use client';

import React, { HTMLAttributes, useEffect } from 'react';
import cx from 'classnames';
import useToggle from 'hooks/useToggle';
import Menu from 'components/Menu';
import Typography from 'components/Typography';
import Dropdown from 'components/Button/Dropdown';
import { CustomTypographyProps } from 'components/Typography/types';
import { Link, usePathname } from 'utils/navigation';
import { ReactComponent as ArrowDownIcon } from 'public/assets/icons/arrow-down.svg';
import styles from './Tab.module.scss';
export interface TabProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  classes?: {
    root?: string;
    link?: string;
    indicator?: string;
  };
  variant: 'primary' | 'secondary' | 'tertiary' | 'quaternary';
  typographyVariant?: CustomTypographyProps['variant'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: any) => void;
  activeLink?: string | number | null;
  withOverflow?: boolean;
  keepVisible?: number;
  links: {
    title: string;
    href?: string | {
      pathname: string;
      params?: Record<string, string>;
    };
    dataLinkAttribute?: unknown;
  }[];
}
const getLinkPathname = (href?: string | {
  pathname: string;
}): string | undefined => {
  if (!href) {
    return href;
  }
  if (typeof href === 'string') {
    return href;
  }
  return href.pathname;
};
const VariantToTypographyVariant: Record<TabProps['variant'], CustomTypographyProps['variant']> = {
  primary: 'h1bk',
  secondary: 'h12bk',
  tertiary: 'h9bk',
  quaternary: 'h9bk'
};
const Tab: React.FC<TabProps> = ({
  className,
  variant,
  typographyVariant,
  onChange,
  activeLink,
  links,
  withOverflow = false,
  keepVisible,
  classes = {},
  ...rest
}) => {
  const pathname = usePathname();
  const [rootRef, setRootRef] = React.useState<HTMLElement | null>(null);
  const [indicatorRef, setIndicatorRef] = React.useState<HTMLDivElement | null>(null);
  const [indexLimit, setIndexLimit] = React.useState<number>(links.length - 1);
  const [isLoaded, load] = useToggle(false);
  useEffect(() => {
    if (rootRef && indicatorRef && typeof activeLink !== 'undefined') {
      let activeItem = rootRef.querySelector<HTMLDivElement>(`.${styles.root} [data-link="${activeLink}"]`);
      if (variant === 'tertiary') {
        const underlineWidth = indicatorRef.offsetWidth;
        if (activeItem) {
          const activeLinkWidth = activeItem.offsetWidth;
          indicatorRef.style.left = `${activeItem.offsetLeft + (activeLinkWidth / 2 - underlineWidth / 2)}px`;
        }
      }
      if (variant === 'secondary') {
        if (withOverflow) {
          const activeInDropdown = links.slice(indexLimit + 1).some(link => link.dataLinkAttribute === activeLink);
          if (activeInDropdown) {
            activeItem = rootRef.querySelector<HTMLDivElement>(`.${styles.link}[data-dropdown="true"]`);
          }
        }
        indicatorRef.style.width = `${activeItem?.offsetWidth}px`;
        indicatorRef.style.left = `${activeItem?.offsetLeft}px`;
      }
      if (!isLoaded) {
        setTimeout(load, 200);
      }
    }
  }, [activeLink, rootRef, indicatorRef, isLoaded, load, variant, withOverflow, links, indexLimit]);
  useEffect(() => {
    if (rootRef && withOverflow) {
      const rootLeftPosition = rootRef.getBoundingClientRect().left;
      if (rootRef.offsetHeight < rootRef.scrollHeight || rootRef.offsetWidth < rootRef.scrollWidth) {
        let lastFullChildIndex = Array.from(rootRef.querySelectorAll(`.${styles.link}`)).findLastIndex(el => {
          return el.getBoundingClientRect().right - rootLeftPosition < rootRef.offsetWidth;
        });
        if (keepVisible) {
          lastFullChildIndex = Math.max(lastFullChildIndex, keepVisible - 1);
        }
        setIndexLimit(lastFullChildIndex - 1);
      }
    }
  }, [rootRef, links, keepVisible, withOverflow]);
  const handleSelectHidden = (item: {
    label: string;
    value: string | number;
  }) => {
    onChange?.(item.value);
  };
  return <nav {...rest} ref={setRootRef} className={cx(styles.root, className, classes.root, {
    [styles.overflow]: withOverflow,
    [styles.primary]: variant === 'primary',
    [styles.secondary]: variant === 'secondary',
    [styles.tertiary]: variant === 'tertiary',
    [styles.quaternary]: variant === 'quaternary'
  })} data-sentry-component="Tab" data-sentry-source-file="Tab.tsx">
      {links.map((link, index) => {
      if (withOverflow && index > indexLimit) {
        return null;
      }
      const classNames = cx(styles.link, classes?.link, {
        [styles.active]: getLinkPathname(link.href) === pathname || typeof activeLink !== 'undefined' && link.dataLinkAttribute === activeLink
      });
      const handleClick = () => {
        const value = typeof link.dataLinkAttribute !== 'undefined' ? link.dataLinkAttribute : index;
        onChange?.(value);
      };
      const titleContent = <Typography variant={typographyVariant ?? VariantToTypographyVariant[variant]}>
            {link.title}
          </Typography>;
      if (link.href) {
        return (
          // @ts-ignore
          <Link href={link.href} key={getLinkPathname(link.href)} className={classNames}>
              {titleContent}
            </Link>
        );
      }
      return <button type="button" onClick={handleClick} className={classNames} key={`${titleContent}${link.dataLinkAttribute}`} data-link={link.dataLinkAttribute}>
            {titleContent}
          </button>;
    })}
      {variant !== 'primary' && <div className={cx(styles.indicator, classes?.indicator, {
      [styles.loaded]: isLoaded
    })} ref={setIndicatorRef} />}
      {withOverflow && indexLimit < links.length - 1 && <Dropdown trigger={<button type="button" className={cx(styles.link, classes?.link, styles.more)} data-dropdown="true">
              <Typography variant={typographyVariant ?? VariantToTypographyVariant[variant]}>
                +{links.length - 1 - indexLimit}
              </Typography>
              <ArrowDownIcon className={styles.icon} />
            </button>} dropdown={<Menu items={links.slice(indexLimit + 1).map(link => ({
      label: link.title,
      value: (link.dataLinkAttribute || link.href) as string
    }))} onSelect={handleSelectHidden}
    // @ts-ignore
    selected={links.find(link => link.dataLinkAttribute === activeLink)} />} />}
    </nav>;
};
export default Tab;