import { useState, useEffect, useCallback } from 'react';
import throttle from 'lodash/throttle';
const THROTTLE_TIME = 300;
const isBrowser = typeof window !== 'undefined';
function getWindowSize() {
  return {
    width: isBrowser ? window.innerWidth : 0,
    height: isBrowser ? window.innerHeight : 0
  };
}
function useWindowResize(enabled = true) {
  const [windowSize, setWindowSize] = useState(() => {
    const {
      width,
      height
    } = getWindowSize();
    return {
      width,
      height
    };
  });
  const onResize = useCallback(throttle(() => {
    setWindowSize(getWindowSize());
  }, THROTTLE_TIME), []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (enabled) {
      window.addEventListener('resize', onResize);
      window.addEventListener('orientationchange', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('orientationchange', onResize);
      };
    }
  }, [enabled, onResize]);
  return windowSize;
}
export default useWindowResize;