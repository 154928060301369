import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import cx from 'classnames';
import { ReactComponent as CheckIcon } from 'public/assets/icons/check.svg';
import styles from './Checkbox.module.scss';
const Checkbox: React.FC<FieldRenderProps<string>> = ({
  input,
  className,
  ...otherProps
}) => {
  return <div className={cx(styles.root, className)} data-sentry-component="Checkbox" data-sentry-source-file="Checkbox.tsx">
      <input className={styles.input} id={`custom-checkbox-${input.name}-${input.value}`} type="checkbox" {...input} {...otherProps} />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={`custom-checkbox-${input.name}-${input.value}`} className={styles.label}
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    tabIndex={0}>
        <CheckIcon className={styles.icon} data-sentry-element="CheckIcon" data-sentry-source-file="Checkbox.tsx" />
      </label>
    </div>;
};
export default Checkbox;