import React from 'react';
import cx from 'classnames';
import Button from 'components/Button';
import Typography from 'components/Typography';
import styles from './Menu.module.scss';
type TItem = {
  value: string | number;
  label: string;
};
interface MenuProps {
  selected?: TItem;
  items: TItem[];
  onSelect: (item: TItem) => void;
  close: () => void;
}
const Menu: React.FC<MenuProps> = ({
  items,
  selected,
  onSelect,
  close
}) => {
  const handleSelect = (item: {
    value: string | number;
    label: string;
  }) => {
    onSelect(item);
    close();
  };
  return <div className={styles.root} data-sentry-component="Menu" data-sentry-source-file="Menu.tsx">
      {items?.map(item => <Button key={item.value} variant="text" className={cx(styles.item, {
      [styles.selected]: item.value === (selected?.value || '')
    })} onClick={() => handleSelect(item)}>
          <Typography variant="h10bk" color="secondary">
            {item.label}
          </Typography>
        </Button>)}
    </div>;
};
export default Menu;