import React, { ReactNode } from 'react';
import type { ButtonProps } from '@mui/material/Button';
import cx from 'classnames';
import Button from 'components/Button';
import HeadlineWithInfo from 'components/HeadlineWithInfo';
import type { HeadlineWithInfoProps } from 'components/HeadlineWithInfo';
import { ReactComponent as DownloadIcon } from 'public/assets/icons/download.svg';
import styles from './ContentHeadline.module.scss';
interface ContentHeadlineProps extends HeadlineWithInfoProps {
  downloadLink?: string;
  rootClassName?: string;
  action?: {
    title: string;
    href?: string;
    onClick?: () => void;
    hideOnMobile?: boolean;
  } & ButtonProps;
  rightSide?: ReactNode;
  leftSide?: ReactNode;
}
const ContentHeadline: React.FC<ContentHeadlineProps> = ({
  children,
  downloadLink,
  action,
  leftSide,
  rightSide,
  rootClassName,
  ...props
}) => {
  return <div className={cx(styles.root, rootClassName)} data-sentry-component="ContentHeadline" data-sentry-source-file="ContentHeadline.tsx">
      <HeadlineWithInfo tooltipProps={{
      placement: 'top'
    }} variant="h7" className={styles.headline} {...props} data-sentry-element="HeadlineWithInfo" data-sentry-source-file="ContentHeadline.tsx">
        {children}
      </HeadlineWithInfo>
      {(!!leftSide || !!rightSide) && <div className={styles.customPanel}>
          <div>{leftSide}</div>
          <div>{rightSide}</div>
        </div>}
      <div className={styles.actions}>
        {downloadLink && <Button variant="secondary" size="medium" rounded className={styles.button} href={downloadLink} target="_blank">
            <DownloadIcon />
          </Button>}
        {!!action && <Button variant="primary" size="medium" className={cx(styles.button, {
        [styles.buttonHideOnMobile]: action.hideOnMobile ?? true
      })} {...action}>
            {action.title}
          </Button>}
      </div>
    </div>;
};
export default ContentHeadline;