'use client';

import React, { ComponentProps, HTMLAttributes, ReactNode, useState } from 'react';
import Menu from '@mui/material/Menu';
import cx from 'classnames';
import Typography from 'components/Typography';
import { ReactComponent as ArrowDownIcon } from 'public/assets/icons/arrow-down.svg';
import styles from './Dropdown.module.scss';
interface DropdownProps extends HTMLAttributes<HTMLButtonElement> {
  dropdown: ReactNode;
  withArrow?: boolean;
  withoutBorder?: boolean;
  trigger?: React.JSX.Element;
  anchorOrigin?: ComponentProps<typeof Menu>['anchorOrigin'];
  transformOrigin?: ComponentProps<typeof Menu>['transformOrigin'];
  classes?: {
    paper?: string;
  };
}
const Dropdown: React.FC<DropdownProps> = ({
  children,
  dropdown,
  trigger,
  withArrow,
  withoutBorder = false,
  className,
  anchorOrigin,
  transformOrigin,
  classes
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return <>
      {trigger ? React.cloneElement(trigger, {
      'data-is-open': open,
      onClick: handleOpen,
      type: 'button'
    }) : <button className={cx(styles.root, className, {
      [styles.borderless]: withoutBorder,
      [styles.open]: open
    })} type="button" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleOpen}>
          <Typography variant="h10bk" color="secondary">
            {children}
          </Typography>
          {withArrow && <ArrowDownIcon className={styles.icon} />}
        </button>}
      <Menu id="basic-menu" disableScrollLock anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{
      'aria-labelledby': 'basic-button'
    }} anchorOrigin={anchorOrigin || {
      vertical: 'bottom',
      horizontal: 'left'
    }} transformOrigin={transformOrigin || {
      vertical: 'top',
      horizontal: 'left'
    }} PaperProps={{
      elevation: 0
    }} classes={{
      paper: cx(styles.paper, classes?.paper)
    }} data-sentry-element="Menu" data-sentry-source-file="Dropdown.tsx">
        {/* @ts-ignore */}
        {dropdown && React.cloneElement(dropdown, {
        close: handleClose
      })}
      </Menu>
    </>;
};
export default Dropdown;