import React from 'react';
import type { ButtonProps } from '@mui/material/Button';
import cx from 'classnames';
import Button from 'components/Button';
import styles from './MobileHeadlineAction.module.scss';
const MobileHeadlineAction: React.FC<ButtonProps> = ({
  className,
  children,
  ...rest
}) => {
  return <div className={cx(styles.root, className)} data-sentry-component="MobileHeadlineAction" data-sentry-source-file="MobileHeadlineAction.tsx">
      <Button variant="primary" {...rest} data-sentry-element="Button" data-sentry-source-file="MobileHeadlineAction.tsx">
        {children}
      </Button>
    </div>;
};
export default MobileHeadlineAction;