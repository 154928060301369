import React from 'react';
import classnames from 'classnames';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import styles from './Text.module.scss';
const TextInput: React.FC<TextFieldProps> = ({
  classes,
  variant = 'primary',
  size = 'm',
  ...otherProps
}) => {
  const {
    InputProps,
    ...textFieldProps
  } = otherProps;
  const rootClassNames = classnames(styles.root, classes?.root);
  const inputClassNames = classnames(styles.input, InputProps?.classes?.input, {
    [styles.primary]: variant === 'primary',
    [styles.secondary]: variant === 'secondary',
    [styles.medium]: size === 'm',
    [styles.large]: size === 'l'
  });
  return <TextField type="text" classes={{
    root: rootClassNames
  }} InputProps={{
    ...InputProps,
    classes: {
      input: inputClassNames,
      error: styles.error
    }
  }} InputLabelProps={{
    classes: {
      root: styles.label,
      disabled: styles.labelDisabled,
      error: styles.labelError
    },
    shrink: true,
    margin: undefined,
    required: false
  }} FormHelperTextProps={{
    classes: {
      root: styles.formHelperText
    }
  }} {...textFieldProps} data-sentry-element="TextField" data-sentry-component="TextInput" data-sentry-source-file="RawTextInput.tsx" />;
};
export default TextInput;