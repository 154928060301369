import React from 'react';
import classnames from 'classnames';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as SearchIcon } from 'public/assets/icons/search.svg';
import type { SearchInputProps } from './types.d';
import styles from './Search.module.scss';
const Search: React.FC<SearchInputProps> = ({
  input,
  meta,
  classes,
  ...otherProps
}) => {
  const {
    InputProps,
    ...textFieldProps
  } = otherProps;
  const rootClassNames = classnames(styles.root, classes?.root);
  const inputClassNames = classnames(styles.input, classes?.input);
  return <TextField type="search" id="search" classes={{
    root: rootClassNames
  }} InputProps={{
    classes: {
      input: inputClassNames,
      error: styles.error
    },
    startAdornment: <InputAdornment position="start" disablePointerEvents={false}>
            <SearchIcon width={24} height={24} />
          </InputAdornment>,
    ...InputProps
  }} InputLabelProps={{
    classes: {
      root: styles.label,
      disabled: styles.labelDisabled,
      error: styles.labelError
    },
    shrink: true,
    margin: undefined,
    required: false
  }} FormHelperTextProps={{
    classes: {
      root: styles.formHelperText
    }
  }} error={meta.touched && typeof (meta.error ?? meta.submitError) !== 'undefined'} helperText={meta.touched && meta.error || meta.submitError} {...textFieldProps} {...input} data-sentry-element="TextField" data-sentry-component="Search" data-sentry-source-file="Search.tsx" />;
};
export default Search;