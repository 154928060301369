import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { bisector } from '@visx/vendor/d3-array';
import { defaultStyles } from '@visx/tooltip';

import type { TDataEntity } from 'components/Chart/types.d';

dayjs.extend(utc);

export const getDataValue = (d: TDataEntity) => d.absolute;
export const getDataChange = (d: TDataEntity) => d.change;
export const getDataDate = (d: TDataEntity) => new Date(d.date);
export const bisectDate = bisector<{ absolute: number; change: number; date: string }, Date>(
  d => new Date(d.date)
).left;
export const dateToUTCString = (date: string) => dayjs(date).utc().format('YYYY-MM-DD');
export const getDataDateInUTC = (d: TDataEntity) => dateToUTCString(d.date);
export const getDataDateTimeInUTC = (d: TDataEntity) =>
  dayjs(d.date).utc().format('YYYY-MM-DDTHH:mm:ss');

const {
  padding,
  borderRadius,
  boxShadow,
  backgroundColor,
  background,
  ...tooltipStylesWithoutDefault
} = defaultStyles;

export const tooltipStyles = tooltipStylesWithoutDefault;
