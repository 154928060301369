'use client';

import React, { createContext, HTMLAttributes, TransitionStartFunction, useMemo, useTransition } from 'react';
interface ITransitionContext {
  inTransition: boolean;
  startTransition: TransitionStartFunction;
}
export const TransitionContext = createContext<ITransitionContext>({
  inTransition: false,
  startTransition: () => {}
});
const TransitionProvider: React.FC<HTMLAttributes<HTMLElement>> = ({
  children
}) => {
  const [inTransition, startTransition] = useTransition();
  const providerValue = useMemo(() => ({
    inTransition,
    startTransition
  }), [inTransition, startTransition]);
  return <TransitionContext.Provider value={providerValue} data-sentry-element="unknown" data-sentry-component="TransitionProvider" data-sentry-source-file="TransitionContext.tsx">{children}</TransitionContext.Provider>;
};
export default TransitionProvider;